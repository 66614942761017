// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { HelmetProvider } from '/Users/zpu/Documents/hs_code/whole-course-front/node_modules/.pnpm/@umijs+renderer-react@4.3.23_nnrd3gsncyragczmpvfhocinkq/node_modules/@umijs/renderer-react';
import { context } from './helmetContext';

export const innerProvider = (container) => {
  return React.createElement(HelmetProvider, { context }, container);
}
