import React from 'react';
import store2 from 'store2';
import { logout } from '@/common/api';
import { history } from '@umijs/max';
import { Modal, Popconfirm, Space } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { encode } from 'js-base64';
import { base64UrlDecode } from './common';

export const formatDate = (date: string, format = 'YYYY-MM-DD HH:mm') => {
  if (date) {
    return dayjs(date).format(format);
  }
  return '';
};

export const doLogout = async () => {
  const { err } = await logout();
  if (err) return;
  store2.remove('__USERNAME__');
  store2.remove('__USER_TOKEN__');
  history.push('/login');
};

export const commonActionRenderWrapper = (
  actionList: {
    name: string;
    label?: string;
    actionFn: (args: any) => void;
  }[],
  isText?: boolean, // 默认显示为图标
) => {
  return (record: any): React.ReactNode => {
    return (
      <Space style={isText ? {} : { fontSize: 20 }} size="middle">
        {actionList.map((item) => {
          if (item.name === 'edit') {
            return (
              <a
                key={item.name}
                title="编辑"
                onClick={async () => {
                  item.actionFn(record);
                }}
              >
                {item.label ? item.label : <FormOutlined />}
              </a>
            );
          } else if (item.name === 'del') {
            return (
              <Popconfirm
                key={item.name}
                title="是否删除该条记录？"
                onConfirm={() => {
                  item.actionFn(record);
                }}
              >
                <a title="删除">
                  {item.label ? item.label : <DeleteOutlined />}
                </a>
              </Popconfirm>
            );
          } else {
            return (
              <a
                key={item.name}
                onClick={async () => {
                  item.actionFn(record);
                }}
              >
                {item.label}
              </a>
            );
          }
        })}
      </Space>
    );
  };
};

export const getCommonColumns = ({
  columns,
  commonActionRender,
  showIndex = true,
  showCreateUser = true,
  actionWidth = 160,
}: {
  columns: Array<ColumnType<any>>;
  commonActionRender?: (record: any) => React.ReactNode;
  showIndex?: boolean;
  showCreateUser?: boolean;
  actionWidth?: number;
}): Array<ColumnType<any>> => {
  let arr: Array<ColumnType<any>> = [];
  if (showIndex) {
    arr.push({
      title: '序号',
      key: 'index',
      width: 80,
      align: 'center',
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
      fixed: 'left',
    });
  }
  arr.push(...columns);
  if (showCreateUser) {
    arr.push(
      {
        title: '创建人',
        key: 'createBy',
        dataIndex: 'createBy',
      },
      {
        title: '创建时间',
        key: 'createTime',
        dataIndex: 'createTime',
        render: (text) => {
          return <>{formatDate(text)}</>;
        },
      },
      {
        title: '修改人',
        key: 'updateBy',
        dataIndex: 'updateBy',
      },
      {
        title: '修改时间',
        key: 'updateTime',
        dataIndex: 'updateTime',
        render: (text) => {
          return <>{formatDate(text)}</>;
        },
      },
    );
  }
  if (commonActionRender) {
    arr.push({
      title: '操作',
      fixed: 'right',
      key: 'action',
      width: actionWidth,
      render: (text, record) => {
        return commonActionRender(record);
      },
    });
  }
  return arr;
};

export const projectStatusMap = new Map([
  ['0', '进行中'],
  ['1', '暂停中'],
  ['2', '已完成'],
]);

export const fieldTypeMap = new Map([
  // 1：短文本 2：长文本  3：数字  4：下拉框  5：分组  6：日期时间 7：表格
  [1, '短文本'],
  [2, '长文本'],
  [3, '数字'],
  [4, '下拉框'],
  [5, '分组'],
  [6, '日期时间'],
  [7, '分组型表格'],
]);

export const generateTree = (data: any[], parentId = 0) => {
  const result = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].parentId === parentId) {
      const children = generateTree(data, data[i].id ?? data[i].menuId);
      if (children.length > 0) {
        data[i].children = children;
      }
      result.push(data[i]);
    }
  }
  return result;
};

/**
 * 重置全局页面修改状态
 * @returns void
 */
export const resetGlobalChange = (): void => {
  window.isChange = false;
};

window.isModuleChange = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
};

export const resetGlobalModule = () => {
  window.isModuleChange = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  };
};

export const doActionWithValidate = ({
  message,
  callback,
  isModule = false,
}: {
  message: string;
  callback: () => void;
  isModule?: boolean;
}) => {
  const showModal = (
    content: string,
    onOk: () => void,
    onCancel?: () => void,
  ) => {
    Modal.confirm({
      title: '提示',
      content,
      centered: true,
      onOk,
      onCancel: onCancel || undefined,
    });
  };

  if (window.isChange) {
    showModal(message, () => {
      resetGlobalModule();
      window.isChange = false;
      callback();
    });
  } else if (isModule) {
    const hasModuleChange = Object.values(window.isModuleChange).some(
      (value) => value,
    );
    if (hasModuleChange) {
      const moduleName = moduleNameMapper.get(Number(window.currentBizCode));
      const moduleMessage = `当前内容页信息已发生更改，是否返回『${moduleName}』主页面确认结果数据？`;
      showModal(
        moduleMessage,
        () => {},
        () => {
          resetGlobalModule();
          callback();
        },
      );
    } else {
      callback();
    }
  } else {
    callback();
  }
};

export const transform = (obj: object) => {
  return Object.entries(obj).map(([name, value]) => {
    if (Array.isArray(value)) {
      value = value.map(transform);
    }
    return { name, value };
  });
};

export const moduleNameMapper = new Map([
  [1, '分户调查'],
  [2, '意愿征询'],
  [3, '确权确户'],
  [4, '分户评估'],
  [5, '协议签约'],
  [6, '搬迁腾空'],
  [7, '安置结算'],
]);

export const isExtensionAllowed = (
  extension: string,
  allowedExtensions: string[],
) => {
  return allowedExtensions
    .map((ext) => ext.toLowerCase())
    .includes(extension.toLowerCase());
};

export const splitSearchParams = (searchParams: string) => {
  if (!searchParams) {
    return {
      formValues: {},
      pagination: {
        pageSize: 10,
        pageNo: 1,
      },
    };
  }
  const data = JSON.parse(base64UrlDecode(searchParams));
  const { current, pageSize, ...restProps } = data;
  return {
    formValues: restProps,
    pagination: {
      pageSize,
      pageNo: current,
    },
  };
};

export const getCommonImageCover = (type: number, url: string) => {
  const imageMapper = new Map([
    [1, '/images/excel.png'],
    [2, '/images/word.png'],
  ]);

  return (
    <a
      href={`http://47.99.156.189:8012/onlinePreview?url=${encodeURIComponent(
        encode(url),
      )}`}
      target="_blank"
    >
      <img
        src={imageMapper.get(type)}
        style={{ objectFit: 'contain', height: 150 }}
      />
    </a>
  );
};

export const getDefaultProjectCover = () => {
  return (
    <div
      style={{
        width: 160,
        height: 120,
        fontSize: 20,
        backgroundColor: '#ebeef5',
        fontWeight: 400,
        color: '#909399',
        fontFamily: 'PingFangSC-Medium, PingFang SC',
        textAlign: 'center',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        position: 'relative',
      }}
    >
      <span>暂</span>
      <span>无</span>
      <span>封</span>
      <span>面</span>
    </div>
  );
};

export const getOtherFileImageCover = () => {
  return (
    <div
      style={{
        height: 150,
        fontSize: 28,
        backgroundColor: '#ebeef5',
        fontWeight: 400,
        color: '#909399',
        fontFamily: 'PingFangSC-Medium, PingFang SC',
        textAlign: 'center',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        position: 'relative',
      }}
    >
      <span>其</span>
      <span>他</span>
      <span>文</span>
      <span>件</span>
    </div>
  );
};

export const business_pattern = {
  namePattern: {
    pattern: /^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/,
    message: '只能输入中英文、数字、下划线，且下划线不能出现在首尾',
  },
};

export const getEverDaySignColumnData = (data: any) => {
  const values = data.dates.map((date: any) => {
    const result = data.projXccEveryDaySignNums.filter(
      (item: any) => item.signTime === date,
    );
    if (result && result.length > 0) {
      return result[0].num;
    } else {
      return 0;
    }
  });

  const newDate = data.dates.map((item: any) => {
    const str = item.substring(5).replace('-', '.');
    const arrStr = str.split('.');
    let sub1 = arrStr[0];
    if (sub1[0] === '0') {
      sub1 = sub1.substring(1);
    }
    let sub2 = arrStr[1];
    if (sub2[0] === '0') {
      sub2 = sub2.substring(1);
    }
    return sub1 + '.' + sub2;
  });

  let result: any[] = [];

  for (let i = 0; i < newDate.length; i++) {
    result.push({
      type: newDate[i],
      value: values[i],
    });
  }
  return result;
};

// 禁用tooltip的配置
export const disabledToolTipConfig = {
  interaction: {
    tooltip: {
      filter: (item: any) => item.value > 1,
      position: 'right',
    },
  },
  tooltip: {
    title: '',
    items: [
      { name: 'ALL：', channel: 'y', color: 'red', valueFormatter: () => '' },
      { name: 'A：', channel: 'y', color: 'green', valueFormatter: () => '' },
    ],
  },
};

export const defaultMapViewDialogObj = {
  1: 'countdown',
  2: 'signRate',
  3: 'calculateTypeNum',
  4: 'everyDaySign',
  5: 'householdTypeStatistics',
  6: 'vacancyRate',
  7: 'groupProgress',
};

export const mapViewDialogTitleMapper = {
  countdown: '倒计时',
  signRate: '签约率',
  vacancyRate: '腾空率',
  calculateTypeNum: '安置选择',
  everyDaySign: '每日签约',
  householdTypeStatistics: '分类签约统计',
  groupProgress: '工作组进度',
};
