/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: any | undefined) {
  const { currentUser, projectInfo, permissionsList } = initialState ?? {};

  const keys = permissionsList?.map((item: any) => item.perms) ?? [];

  const hasProjectInfo =
    keys.filter((key: string) => key.startsWith('project:info:')).length > 0;
  const hasProjectConfig =
    keys.filter((key: string) => key.startsWith('project:config:')).length > 0;

  const result = keys.reduce((acc: any, cur: string) => {
    acc[cur] = true;
    return acc;
  }, {});

  const defaultProjectAccess = {
    'project:info': currentUser?.admin || hasProjectInfo,
    'project:info:overview': currentUser?.admin,
    'project:info:flow-plan': currentUser?.admin,
    'project:info:household': currentUser?.admin,
    'project:info:problem-summary': currentUser?.admin,
    'project:config': currentUser?.admin || hasProjectConfig,
    'project:config:basic': currentUser?.admin,
    'project:config:form': currentUser?.admin,
    'project:config:template': currentUser?.admin,
    'project:config:dictionary': currentUser?.admin,
    'project:config:mapConfig': currentUser?.admin,
    'project:config:mapEdit': currentUser?.admin,
  };

  // 如果不是管理员
  if (!currentUser?.admin) {
    let moduleJson = projectInfo?.moduleJson || '{}';
    try {
      moduleJson = JSON.parse(moduleJson);
    } catch (e) {
      moduleJson = {};
    }

    Object.keys(moduleJson).forEach((key) => {
      result[key] = !!result[key] && !!moduleJson[key];
    });
  }

  return {
    canAdmin: currentUser?.admin,
    ...defaultProjectAccess,
    ...result,
  };
}
