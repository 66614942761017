import { ColorPicker } from 'antd';
import { observer } from '@formily/react';
import { useEffect, useState } from 'react';
import { Color } from 'antd/es/color-picker';

export default observer((props: any) => {
  const { value, onChange, ...restProps } = props;
  const [color, setColor] = useState<Color>(value);

  return (
    <ColorPicker
      value={value}
      onChange={(color) => {
        setColor(color);
        onChange(`#${color.toHex()}`);
      }}
      {...restProps}
    />
  );
});
