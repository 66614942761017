import { createForm } from '@formily/core';
import { message } from 'antd';
import React, { useMemo } from 'react';
import { changePwd } from './api';
import { doLogout } from '@/utils';
import { Form, FormItem, Password } from '@formily/antd-v5';
import { Field } from '@formily/react';
import { MyModal } from '@/components';

interface IChangePwdModal {
  onCancel: () => void;
}

export default (props: IChangePwdModal) => {
  const { onCancel } = props;

  const form = useMemo(() => createForm(), []);

  const submitFn = async () => {
    const data: any = await form.submit();
    const { oldPassword, newPassword } = data;
    const { err } = await changePwd({
      params: {
        oldPassword,
        newPassword,
      },
    });
    if (err) {
      message.error('修改失败');
      return;
    }
    doLogout();
  };

  return (
    <MyModal
      open
      title="修改密码"
      onCancel={onCancel}
      onOk={submitFn}
      width={660}
      className="update-password-modal"
    >
      <Form form={form} style={{ padding: '10px 15px 10px' }} labelCol={5}>
        <Field
          name="oldPassword"
          title="旧密码"
          component={[Password, { placeholder: '请输入密码', size: 'large' }]}
          decorator={[FormItem]}
          validator={[
            {
              required: true,
              message: '旧密码不能为空',
            },
          ]}
        />
        <Field
          name="newPassword"
          title="新密码"
          component={[
            Password,
            { placeholder: '请输入密码', size: 'large', checkStrength: true },
          ]}
          decorator={[FormItem]}
          validator={[
            {
              required: true,
              message: '新密码不能为空',
            },
          ]}
          reactions={(field) => {
            const confirm = field.query('.confirmPassword');
            field.selfErrors =
              confirm.get('value') &&
              field.value &&
              field.value !== confirm.get('value')
                ? ['确认密码不匹配']
                : [];
          }}
        />
        <Field
          name="confirmPassword"
          title="确认新密码"
          component={[
            Password,
            { placeholder: '请输入密码', size: 'large', checkStrength: true },
          ]}
          decorator={[FormItem]}
          validator={[
            {
              required: true,
              message: '确认密码不能为空',
            },
          ]}
          reactions={(field) => {
            const confirm = field.query('.newPassword');
            field.selfErrors =
              confirm.get('value') &&
              field.value &&
              field.value !== confirm.get('value')
                ? ['确认密码不匹配']
                : [];
          }}
        />
      </Form>
    </MyModal>
  );
};
