import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { uploadImg } from '@/common/api';
import '@ckeditor/ckeditor5-build-classic/build/translations/zh-cn';

class MyUploadAdapter {
  loader: any;
  constructor(loader: any) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file: File) =>
        new Promise((resolve, reject) => {
          uploadImg({
            data: {
              file,
              platform: 'web',
              prefix: 'editor',
            },
          })
            .then((res) => {
              const { err, data } = res;
              if (err) {
                reject(err);
              } else {
                resolve({
                  default: data,
                });
              }
            })
            .catch((err) => {
              reject(err);
            });
        }),
    );
  }

  abort() {
    // Handle the abort request
  }
}

function MyUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new MyUploadAdapter(loader);
  };
}

export default forwardRef(
  (prop: { title: string; style?: CSSProperties }, ref) => {
    const { title, style = {} } = prop;

    const editorRef = useRef<any>(null);
    const pendingDataRef = useRef<string | null>(null);

    const handleEditorReady = (editor: any) => {
      editorRef.current = editor;

      // 如果有待设置的数据，设置它
      if (pendingDataRef.current !== null) {
        editor.setData(pendingDataRef.current);
        pendingDataRef.current = null;
      }
    };

    useImperativeHandle(ref, () => ({
      getEditorValue: () => {
        const data = editorRef.current?.getData();
        return data;
      },
      setEditorValue: (value: string) => {
        if (editorRef.current) {
          editorRef.current.setData(value);
        } else {
          // 如果编辑器还没有初始化完成，保存这个值
          pendingDataRef.current = value;
        }
      },
    }));

    return (
      <div style={style}>
        <h2 style={{ fontSize: 16, marginBottom: 10 }}>{title}</h2>
        <CKEditor
          editor={ClassicEditor}
          data=""
          config={{
            extraPlugins: [MyUploadAdapterPlugin],
            language: 'zh-cn', // 设置语言为简体中文
          }}
          onReady={handleEditorReady}
        />
      </div>
    );
  },
);
