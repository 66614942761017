// import menu from './zh-CN/menu';

export default {
  'menu.home': '首页',
  'menu.project': '项目',
  'menu.project.list': '项目列表',
  'menu.project.info': '项目信息',
  'menu.project.info.overview': '项目概览',
  'menu.project.info.flow-plan': '流程计划',
  'menu.project.info.household': '分户档案',
  'menu.project.info.household-detail': '档案编辑',
  'menu.project.info.problem-summary': '问题汇总',
  'menu.project.info.map-view': '签约屏展',
  'menu.project.config': '项目配置',
  'menu.project.config.basic': '项目设置',
  'menu.project.config.form': '表单设置',
  'menu.project.config.template': '模板设置',
  'menu.project.config.dictionary': '价格字典',
  'menu.project.config.dictionary-type': '字典类别',
  'menu.project.config.dictionary-detail': '字典明细',
  'menu.project.config.map-config': '地图配置',
  'menu.project.config.map-edit': '地图绘制',
  'menu.project.config.log': '操作日志',
  'menu.setting': '设置',
  'menu.setting.proj': '项目设置',
  'menu.setting.proj.institution-manage': '机构管理',
  'menu.setting.proj.user-manage': '用户管理',
  'menu.setting.proj.project-manage': '项目管理',
  'menu.setting.proj.preset-roles': '预设角色',
  'menu.setting.proj.project-menu-permission': '项目菜单',
  'menu.setting.sys': '系统工具',
  'menu.setting.sys.dict-manage': '字典管理',
  'menu.setting.sys.dict-manage-detail': '字典管理详情',
  'menu.setting.sys.gen-code': '生成代码',
  'menu.setting.sys.monitor-log': '行为日志',
};
