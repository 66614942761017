// 运行时配置
import { matchRoutes, history, Link } from '@umijs/max';
import defaultSettings from '../config/defaultSettings';
import {
  MenuDataItem,
  // SettingDrawer,
  type Settings as LayoutSettings,
} from '@ant-design/pro-components';
import {
  getLoginInfo,
  getProcessInfo,
  getProjectInfo,
  getmenuListByPid,
} from './common/api';
import { AvatarDropdown, AvatarName } from './components';
import queryString from 'query-string';
import { ConfigProvider, Space, Row, Tooltip, Modal } from 'antd';
import {
  resetGlobalChange,
  resetGlobalModule,
  showModalConfirm,
} from './utils';
import { CalculatorFilled, LockFilled } from '@ant-design/icons';
import store2 from 'store2';
import { Calculator } from 'react-mac-calculator';

const isDev = process.env.NODE_ENV === 'development';

const loginPath = '/login';
const ignorePathList = ['/login', '/test', '/mobile-map-view'];

/**
 * 路由切换回调方法
 * @export
 * @param {{ matchedRoutes }} { matchedRoutes 匹配到的路由 }
 * @return void
 */
export function onRouteChange({ clientRoutes, location }: any) {
  const route = matchRoutes(clientRoutes, location.pathname)?.pop()?.route;
  if (route) {
    // @ts-ignore
    document.title = route.title || '';
  }
}

export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
  fetchProjectInfo?: (id: any) => Promise<any>;
  projectInfo?: any;
  permissionsList?: any[];
  platformSettingProcess?: any;
}> {
  const fetchUserInfo = async () => {
    try {
      const { data } = await getLoginInfo();
      return data;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  const fetchProjectInfo = async (id: any) => {
    const [info, list, platformSettingProcess] = await Promise.all([
      await getProjectInfo({
        args: {
          id,
        },
      }),
      await getmenuListByPid({
        params: {
          pid: id,
        },
      }),
      await getProcessInfo({
        args: {
          proId: id,
        },
      }),
    ]);
    if (info.err || list.err || platformSettingProcess.err) {
      return {};
    }
    sessionStorage.setItem('projectName', info.data.name);
    return {
      projectInfo: info.data,
      permissionsList: list.data,
      platformSettingProcess: platformSettingProcess.data[0] ?? {},
    };
  };
  // 如果不是登录页面，执行
  const { location } = history;
  if (!ignorePathList.includes(location.pathname)) {
    const currentUser = await fetchUserInfo();
    let projectInfo = {};
    let permissionsList = [];
    let platformSettingProcess = {};
    if (location.pathname.startsWith('/project/')) {
      const query = queryString.parse(history.location.search);
      const result = await fetchProjectInfo(query.id);
      projectInfo = result.projectInfo || {};
      permissionsList = result.permissionsList || [];
      platformSettingProcess = result.platformSettingProcess;
    }
    return {
      fetchUserInfo,
      fetchProjectInfo,
      currentUser,
      projectInfo,
      permissionsList,
      platformSettingProcess,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  }
  return {
    fetchUserInfo,
    fetchProjectInfo,
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout = ({ initialState, setInitialState }: any) => {
  return {
    siderWidth: 200,
    collapsed: false,
    actionsRender: () => {
      return [
        <Row align="middle" style={{ marginRight: 20 }}>
          <Space size="middle">
            <Row
              className="custom-action-item"
              align="middle"
              onClick={() => {
                Modal.confirm({
                  content: <Calculator />,
                  // wrapClassName: 'custom-calc-modal-wrapper',
                  className: 'custom-calc-modal',
                  width: 320,
                  icon: null, // 去除默认的警告图标
                  footer: null, // 去除默认的底部按钮
                  keyboard: true, // 允许使用Esc键关闭
                  centered: true,
                  // mask: false,
                });
              }}
            >
              <CalculatorFilled style={{ fontSize: 20, marginRight: 5 }} />
              计算器
            </Row>
            <Row
              className="custom-action-item"
              align="middle"
              onClick={() => {
                const hash = window.location.hash;
                store2.set('lockPath', hash.slice(1));
                history.push('/lockScreen');
              }}
            >
              <LockFilled style={{ fontSize: 20, marginRight: 5 }} />
              锁屏
            </Row>
          </Space>
        </Row>,
      ];
    },
    avatarProps: {
      src: initialState?.currentUser?.avatar
        ? initialState?.currentUser?.avatar
        : '/images/profile.jpg',
      title: <AvatarName />,
      render: (_: any, avatarChildren: any) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
    waterMarkProps: {
      content: initialState?.currentUser?.name,
    },
    footerRender: () => <></>,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (
        !initialState?.currentUser &&
        !ignorePathList.includes(location.pathname)
      ) {
        history.push(loginPath);
      }
      const lockPath = store2.get('lockPath');
      if (lockPath) {
        history.push('/lockScreen');
      }
    },
    bgLayoutImgList: [],
    links: [],
    menuHeaderRender: undefined,
    menuExtraRender: (menuProps: any) => {
      const pathname = menuProps.location.pathname;
      const projectInfo = initialState?.projectInfo || {};
      if (pathname.startsWith('/project/')) {
        return (
          <div
            style={{
              height: 36,
              background: '#007EB4',
              color: '#fff',
              textAlign: 'center',
              lineHeight: '36px',
              padding: '0 10px',
              boxShadow:
                '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              borderRadius: 4,
              marginBottom: 10,
            }}
          >
            <Tooltip title={projectInfo.name}>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  fontSize: 18,
                }}
              >
                {projectInfo.name}
              </div>
            </Tooltip>
          </div>
        );
      }
      return <></>;
    },
    menuItemRender: (itemProps: MenuDataItem) => {
      let pathname = itemProps.path || '';
      const query = queryString.parse(history.location.search);
      if (pathname.startsWith('/project/') && query.id) {
        pathname += `?id=${query.id}`;
      }
      return (
        <Link
          to={pathname}
          onClick={(event) => {
            event.preventDefault();
            const callback = () => {
              resetGlobalModule();
              if (itemProps.target && itemProps.target === '_blank') {
                window.open(
                  `${window.location.origin}${window.location.pathname}#${
                    itemProps.url
                  }?pid=${query.id}&projectName=${sessionStorage.getItem(
                    'projectName',
                  )}`,
                  '_blank',
                );
              } else {
                history.push(pathname);
              }
            };
            if (window.isChange) {
              showModalConfirm({
                title: '提示',
                content: '当前页面有未保存的内容，确定要离开么？',
                onOk: async () => {
                  resetGlobalChange();
                  callback();
                },
              });
            } else {
              callback();
            }
          }}
        >
          {itemProps.name}
        </Link>
      );
    },
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children: any) => {
      // if (initialState?.loading) return <PageLoading />;
      return (
        <ConfigProvider
          theme={{
            token: {
              fontSize: 16,
            },
          }}
        >
          {children}
          {/* {isDev && (
            <SettingDrawer
              disableUrlParams
              enableDarkTheme
              settings={initialState?.settings}
              onSettingChange={(settings) => {
                setInitialState((preInitialState: any) => ({
                  ...preInitialState,
                  settings,
                }));
              }}
            />
          )} */}
        </ConfigProvider>
      );
    },
    ...initialState?.settings,
  };
};
