import { createForm } from '@formily/core';
import React, { useMemo } from 'react';
import { Form, FormItem, Input, Radio, Select } from '@formily/antd-v5';
import { Field } from '@formily/react';
import { CardUpload, MyModal } from '@/components';
import { editUserInfo } from './api';
import { getFileUrl } from '@/utils';

interface IEditModal {
  detailInfo: any;
  onCancel: () => void;
  callback: (data: any) => void;
}

export default (props: IEditModal) => {
  const { detailInfo, onCancel, callback } = props;

  const form = useMemo(
    () =>
      createForm({
        initialValues: {
          ...detailInfo,
          avatar: detailInfo.avatar
            ? [
                {
                  uid: '-1',
                  name: detailInfo.avatar.substring(
                    detailInfo.avatar.lastIndexOf('/') + 1,
                  ),
                  status: 'done',
                  url: detailInfo.avatar,
                  response: detailInfo.avatar,
                },
              ]
            : [],
        },
      }),
    [detailInfo],
  );

  const submitFn = async () => {
    const data: any = await form.submit();
    const { avatar } = data;
    const newAvatar = await getFileUrl(avatar, detailInfo?.avatar, {
      platform: 'web',
      prefix: 'user',
    });
    const postData = {
      ...data,
      avatar: newAvatar,
    };
    const { err } = await editUserInfo({
      data: postData,
    });
    if (err) return;
    callback(postData);
  };

  return (
    <MyModal
      open
      title={`个人设置`}
      onCancel={onCancel}
      onOk={submitFn}
      width={660}
    >
      <Form form={form} labelCol={5} wrapperCol={18} style={{ paddingTop: 20 }}>
        <Field
          name="nickName"
          title="用户姓名"
          component={[Input, { placeholder: '请输入用户姓名' }]}
          decorator={[FormItem]}
          validator={[
            {
              required: true,
              message: '用户姓名不能为空',
            },
          ]}
        />
        <Field
          name="phonenumber"
          title="手机号码"
          component={[Input, { placeholder: '请输入手机号码' }]}
          decorator={[FormItem]}
          validator={[
            {
              format: 'phone',
              message: '手机号格式不正确',
            },
          ]}
        />
        <Field
          name="address"
          title="联系地址"
          component={[Input, { placeholder: '请输入联系地址' }]}
          decorator={[FormItem]}
          validator={[]}
        />
        <Field
          name="sex"
          title="性别"
          component={[Radio.Group]}
          dataSource={[
            { label: '男', value: '0' },
            { label: '女', value: '1' },
            { label: '未知', value: '2' },
          ]}
          decorator={[FormItem]}
          validator={[]}
          initialValue={'2'}
        />
        <Field
          name="avatar"
          title="用户头像"
          component={[
            CardUpload,
            {
              maxCount: 1,
            },
          ]}
          decorator={[FormItem]}
        />
      </Form>
    </MyModal>
  );
};
