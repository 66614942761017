import { MenuOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React from 'react';
import type { SortEnd, SortableContainerProps } from 'react-sortable-hoc';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import './index.less';

interface IDragTable {
  loading: boolean;
  dataSource: any[];
  columns: any[];
  rowKey: string;
  hasSortAuth?: boolean;
  onSortEnd: (sortEnd: SortEnd) => void;
}

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />,
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
  ),
);

export default (props: IDragTable) => {
  const {
    loading,
    dataSource,
    columns,
    rowKey,
    onSortEnd,
    hasSortAuth = true,
  } = props;

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x[rowKey] === restProps['data-row-key'],
    );
    return <SortableItem index={index} {...restProps} />;
  };

  let newColumns: Array<ColumnType<any>> = [];

  if (hasSortAuth) {
    newColumns = [
      {
        title: '',
        key: 'sortHandler',
        dataIndex: 'sortHandler',
        width: 50,
        render: () => <DragHandle />,
      },
      ...columns,
    ];
  } else {
    newColumns = columns;
  }

  return (
    <Table
      loading={loading}
      pagination={false}
      dataSource={dataSource}
      columns={newColumns}
      rowKey={rowKey}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};
