import makeRequest from '@/request';

// 上传文件
export const uploadImg = makeRequest({
  url: '/proj/file/upload',
  method: 'POST',
  headers: {
    // 按照上面的写法修改Content-type
    'Content-Type': 'multipart/form-data',
  },
});

// 上传文件
export const batchUploadImg = makeRequest({
  url: '/proj/file/batchUpload',
  method: 'POST',
  headers: {
    // 按照上面的写法修改Content-type
    'Content-Type': 'multipart/form-data',
  },
});

// 删除文件
export const deleteImg = makeRequest({
  url: '/proj/file/del',
  method: 'POST',
});

// 登出
export const logout = makeRequest({
  url: '/logout',
  method: 'POST',
});

// 获取当前用户信息
export const getLoginInfo = makeRequest({
  url: '/getInfo',
  method: 'GET',
});

// 获取所有的机构列表
export const getinstitutionList = makeRequest({
  url: '/sys/institution/list',
  method: 'GET',
});

export const getinstitutionListByPid = makeRequest({
  url: '/sys/institution/listByPid',
  method: 'GET',
});

export const getProjectInfo = makeRequest({
  url: '/proj/project/{id}',
  method: 'GET',
});

// 获取工作组列表
export const getWorkGroupList = makeRequest({
  url: '/proj/group/list',
  method: 'GET',
});

// 获取所有菜单列表
export const getMenuList = makeRequest({
  url: '/sys/menu/list',
  method: 'GET',
});

export const getRoleInfo = makeRequest({
  url: '/sys/menu/menuList',
  method: 'GET',
});

export const getmenuListByPid = makeRequest({
  url: '/sys/menu/menuListByPid',
  method: 'GET',
});

// 获取所有的安置方式列表
export const getCalculateTypeList = makeRequest({
  url: '/proj/calculateType/list',
  method: 'GET',
});

export const getNumberRuleList = makeRequest({
  url: '/proj/numberRule/list',
  method: 'GET',
});

export const getProjMapArrangementList = makeRequest({
  url: '/proj/mapArrangement/getProjMapArrangement',
  method: 'GET',
});

export const saveOrUpdateProjMapArrangement = makeRequest({
  url: '/proj/mapArrangement/saveOrUpdate',
  method: 'POST',
  desc: '操作',
});

// 删除问题
export const deleteProblemDetail = makeRequest({
  url: '/proj/problemDetail/{ids}',
  method: 'DELETE',
  desc: '操作',
});

// 获取展示端设置的数据
export const getDisplayInfo = makeRequest({
  url: '/proj/platformSettingDisplay/{proId}',
  method: 'GET',
});

// 获取表格列头设置的数据
export const getTableHeadSetList = makeRequest({
  url: '/proj/tableHeadSet/list',
  method: 'GET',
});

// 每日签约数据
export const getEveryDaySignNum = makeRequest({
  url: '/proj/largeScreenDisplay/everyDaySignNum',
  method: 'GET',
});

// 安置选择数据
export const getCalculateTypeNum = makeRequest({
  url: '/proj/largeScreenDisplay/calculateTypeNum',
  method: 'GET',
});

// 签约率数据
export const getSignRate = makeRequest({
  url: '/proj/largeScreenDisplay/signRate',
  method: 'GET',
});

export const getVacateRate = makeRequest({
  url: '/proj/largeScreenDisplay/vacateRate',
  method: 'GET',
});

// 分类签约数据
export const getHouseholdTypeSignCount = makeRequest({
  url: '/proj/largeScreenDisplay/householdTypeSignCount',
  method: 'GET',
});

// 工作组数据
export const getGroupProgress = makeRequest({
  url: '/proj/largeScreenDisplay/groupProgress',
  method: 'GET',
});

// 全过程设置
export const getProcessInfo = makeRequest({
  url: '/proj/platformSettingProcess/{proId}',
  method: 'GET',
});
