import { Dropdown } from 'antd';
import type { DropDownProps } from 'antd/es/dropdown';
import React from 'react';
import classNames from 'classnames';
import { createStyles } from 'antd-style';

export type HeaderDropdownProps = {
  overlayClassName?: string;
  placement?:
    | 'bottomLeft'
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomCenter';
} & Omit<DropDownProps, 'overlay'>;

const useStyles = createStyles(({ token }) => ({
  dropdownClassName: {
    [`@media screen and (max-width: ${token.screenXS}px)`]: {
      width: '100%',
    },
  },
}));

const HeaderDropdown: React.FC<HeaderDropdownProps> = ({
  overlayClassName: cls,
  ...restProps
}) => {
  const { styles } = useStyles();

  return (
    <Dropdown
      overlayClassName={classNames(styles.dropdownClassName, cls)}
      {...restProps}
    />
  );
};

export default HeaderDropdown;
