import { PlusOutlined } from '@ant-design/icons';
import { ArrayField, isField } from '@formily/core';
import { observer, useField } from '@formily/react';
import { Button, message } from 'antd';
import React from 'react';
import { ArrayItems } from '@formily/antd-v5';

export default observer(({ name, limit }: { name: string; limit: number }) => {
  const field = useField();

  const objField = field.query(name).take();

  const index = ArrayItems.useIndex();

  return (
    <Button
      type="dashed"
      block
      icon={<PlusOutlined />}
      style={{
        marginLeft: 5,
      }}
      onClick={() => {
        if (!isField(objField)) return;
        if (!objField.value) return;
        const len = objField.value.length;

        if (limit) {
          if (len >= limit) {
            return message.error(`最多不能超过${limit}个`);
          } else {
            (objField as ArrayField).insert(index + 1, '');
          }
        } else {
          (objField as ArrayField).insert(index + 1, '');
        }
      }}
    ></Button>
  );
});
