import { NumberPicker } from '@formily/antd-v5';
import { observer } from '@formily/react';

export default observer((props: any) => {
  const { value, onChange, ...restProps } = props;
  // 这个value可能是数字，如果不是数字，则变成0
  let newValue = value;
  if (
    isNaN(newValue) ||
    value === '' ||
    value === undefined ||
    value === null
  ) {
    newValue = 0;
  }

  const onCustomChange = (value: any) => {
    if (value === '' || value === undefined || value === null) {
      onChange(0);
    } else {
      onChange(value);
    }
  };

  return (
    <NumberPicker value={newValue} onChange={onCustomChange} {...restProps} />
  );
});
