import React, { useRef, useState, useEffect } from 'react';
import { Tooltip } from 'antd';

interface EllipsisTextProps {
  text: string;
  maxWidth?: number; // 可选，指定文本容器的最大宽度
  isBold?: boolean;
}

const EllipsisText: React.FC<EllipsisTextProps> = ({
  text,
  maxWidth = 200,
  isBold = false,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const { scrollWidth, clientWidth } = textRef.current;
        setIsOverflow(scrollWidth > clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [text]);

  return (
    <Tooltip title={isOverflow ? text : undefined}>
      <div
        ref={textRef}
        style={{
          maxWidth: maxWidth,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: isBold ? 'bold' : 'normal',
          // cursor: isOverflow ? 'pointer' : 'default',
        }}
      >
        {text}
      </div>
    </Tooltip>
  );
};

export default EllipsisText;
