import React from 'react';
import ReactJsonViewCompare from 'react-json-view-compare';
import MyModal from '../MyModal';

interface IDiffModal {
  modalVisible: boolean;
  detailInfo: any;
  onCancel: () => void;
}

export default (props: IDiffModal) => {
  const { modalVisible, detailInfo, onCancel } = props;
  let oldData = detailInfo.beforeParam
    ? JSON.parse(detailInfo.beforeParam)
    : {};
  if (oldData['分户调查结果']) {
    oldData['分户调查结果'] = JSON.parse(oldData['分户调查结果']);
  }
  const newData = detailInfo.afterParam
    ? JSON.parse(detailInfo.afterParam)
    : {};
  if (newData['分户调查结果']) {
    newData['分户调查结果'] = JSON.parse(newData['分户调查结果']);
  }

  return (
    <MyModal
      title="变更记录"
      open={modalVisible}
      footer={null}
      onCancel={onCancel}
      maskClosable={true}
      width={600}
    >
      <div style={{ maxHeight: 500, overflow: 'auto' }}>
        <ReactJsonViewCompare oldData={oldData} newData={newData} />
      </div>
    </MyModal>
  );
};
