import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import type { BackendResultFormat, RequestConfig } from '..';
import { history } from '@umijs/max';
import store2 from 'store2';

const login = {
  request: {
    onFulfilled: async (config: RequestConfig) => {
      const token = store2.get('__USERTOKEN__');
      if (token) {
        // 如果有token，就把token放到请求头里面
        // @ts-ignore
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      // @ts-ignore
      config.headers['Datasource'] = 'master';
      return config;
    },
  },
  response: {
    onFulfilled: (response: AxiosResponse<BackendResultFormat>) => {
      const { code, msg } = response.data;
      const { pathname, search } = history.location;
      const fullPath = pathname + search;
      if ([401, 403].includes(code) && pathname !== '/login') {
        // message.error(msg);
        history.push({
          pathname: '/login',
          search: `?redirectUrl=${encodeURIComponent(fullPath)}`,
        });
      }
      return response;
    },
  },
};

export default login;
