import { useModel, useAccess } from '@umijs/max';
import { Result } from 'antd';
import React, { useEffect } from 'react';

interface IAuthPageContainer {
  auth: string;
  children: React.ReactNode;
  doAction?: () => void;
}

export default (props: IAuthPageContainer) => {
  const { initialState } = useModel('@@initialState');
  const access = useAccess();

  const { currentUser } = initialState ?? {};

  const flag = access[props.auth] || currentUser?.admin;

  useEffect(() => {
    if (flag && props.doAction) {
      props.doAction();
    }
  }, []);

  if (flag) {
    return props.children;
  }

  return <Result status="403" title="403" subTitle="没有权限，请联系管理员" />;
};
