import { Button, DatePicker, Form, FormInstance, Input } from 'antd';
import React from 'react';

const { RangePicker } = DatePicker;

interface IOperationSearchForm {
  form: FormInstance;
  doSearch: () => void;
}

export default (props: IOperationSearchForm) => {
  const { form, doSearch } = props;
  return (
    <Form layout={'inline'} form={form}>
      <Form.Item name="title">
        <Input placeholder="按标题搜索" />
      </Form.Item>
      <Form.Item name="operName">
        <Input placeholder="按操作人搜索" />
      </Form.Item>
      <Form.Item name="operTime">
        <RangePicker
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm:ss"
        />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          className="button-color-dust"
          onClick={doSearch}
        >
          搜索
        </Button>
      </Form.Item>
    </Form>
  );
};
