import React from 'react';
import { Popconfirm, PopconfirmProps } from 'antd';

export default (props: PopconfirmProps) => {
  const { children, onConfirm, onCancel, title } = props;

  return (
    <Popconfirm
      title={title}
      onConfirm={(ev) => {
        ev?.stopPropagation();
        onConfirm && onConfirm(ev);
      }}
      onCancel={(ev) => {
        ev?.stopPropagation();
        onCancel && onCancel(ev);
      }}
    >
      <div
        onClick={(ev) => {
          ev?.stopPropagation();
        }}
      >
        {children}
      </div>
    </Popconfirm>
  );
};
