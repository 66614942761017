import { BaseSelect, MyModal } from '@/components';
import {
  Select,
  DatePicker,
  Form,
  FormGrid,
  FormItem,
  Radio,
} from '@formily/antd-v5';
import { createForm } from '@formily/core';
import { Field } from '@formily/react';
import React, { useEffect, useMemo, useRef } from 'react';
import RichEditor from './RichEditor';
import {
  getProblemDetail,
  saveProblemDetail,
  updateProblemDetail,
} from './api';

const { GridColumn } = FormGrid;

interface IEditModal {
  detailInfo: any;
  postData: any;
  onCancel: () => void;
  callback: () => void;
}

export default (props: IEditModal) => {
  const { detailInfo, onCancel, postData, callback } = props;

  const { projectId } = postData;

  const problemDetailRef = useRef<any>(null);
  const answerRef = useRef<any>(null);

  const actionName = useMemo(() => {
    return detailInfo.id ? '编辑' : '创建';
  }, [detailInfo]);

  const form = useMemo(
    () =>
      createForm({
        initialValues: detailInfo,
      }),
    [detailInfo],
  );

  const fetchDetail = async (id: string) => {
    const { data, err } = await getProblemDetail({
      args: {
        id,
      },
    });
    if (err) return;
    const { answer = '', problemDetail = '' } = data;
    answerRef.current?.setEditorValue(answer);
    problemDetailRef.current?.setEditorValue(problemDetail);
  };

  const submitFn = async () => {
    const problemDetail = problemDetailRef.current?.getEditorValue();
    const answer = answerRef.current?.getEditorValue();
    const values: any = await form.submit();
    const { id } = values;
    if (!id) {
      const { err } = await saveProblemDetail({
        data: {
          ...values,
          ...postData,
          problemDetail,
          answer,
        },
      });
      if (err) return;
    } else {
      const { err } = await updateProblemDetail({
        data: {
          ...values,
          problemDetail,
          answer,
        },
      });
      if (err) return;
    }
    callback();
  };

  useEffect(() => {
    if (detailInfo.id) {
      fetchDetail(detailInfo.id);
    }
  }, [detailInfo.id]);

  let problemTypeList: any[] = [];

  if (detailInfo.id) {
    problemTypeList = [
      {
        id: detailInfo.projProblemTypeId,
        problemType: detailInfo.problemType,
      },
    ];
  }

  return (
    <MyModal
      open
      title={`${actionName}问题`}
      onCancel={onCancel}
      onOk={submitFn}
      width={1000}
    >
      <Form form={form} labelWidth={100}>
        <FormGrid maxColumns={3} minColumns={3} columnGap={10}>
          <GridColumn>
            <Field
              name="projProblemTypeId"
              title="问题类型"
              component={[
                BaseSelect,
                {
                  url: `/proj/problemType/list?proId=${projectId}`,
                  mapping: {
                    key: 'id',
                    value: 'problemType',
                  },
                  placeholder: '请选择问题类型',
                  list: problemTypeList,
                  style: {
                    maxWidth: 210,
                  },
                },
              ]}
              decorator={[FormItem]}
              validator={[
                {
                  required: true,
                  message: '问题类型不能为空',
                },
              ]}
            />
          </GridColumn>
          <GridColumn>
            <Field
              name="difficultyLevel"
              title="难易度"
              component={[Select]}
              dataSource={[
                // 1:容易  2:一般  3:困难  4:极难
                { label: '容易', value: 1 },
                { label: '一般', value: 2 },
                { label: '困难', value: 3 },
                { label: '极难', value: 4 },
              ]}
              decorator={[FormItem]}
              initialValue={1}
            />
          </GridColumn>
          <GridColumn></GridColumn>
          <GridColumn>
            <Field
              name="urgentLevel"
              title="紧急程度"
              component={[Select]}
              dataSource={[
                //  1:较低   2:普通   3:较高   4:最高
                { label: '较低', value: 1 },
                { label: '普通', value: 2 },
                { label: '较高', value: 3 },
                { label: '最高', value: 4 },
              ]}
              decorator={[FormItem]}
              initialValue={2}
            />
          </GridColumn>
          <GridColumn>
            <Field
              name="talkMethod"
              title="交流途径"
              component={[Select]}
              dataSource={[
                //  1:电话  2:走访
                { label: '电话', value: 1 },
                { label: '走访', value: 2 },
              ]}
              decorator={[FormItem]}
              initialValue={1}
            />
          </GridColumn>
          <GridColumn>
            <Field
              name="talkTime"
              title="交流日期"
              component={[
                DatePicker,
                {
                  format: 'YYYY-MM-DD HH:mm:ss',
                  showTime: true,
                },
              ]}
              decorator={[FormItem]}
            />
          </GridColumn>
          <GridColumn gridSpan={3}>
            <Field
              name="result"
              title="处理结果"
              component={[Radio.Group]}
              dataSource={[
                // 1:未解决 2:已解决
                { label: '未解决', value: 1 },
                { label: '已解决', value: 2 },
              ]}
              decorator={[FormItem]}
              initialValue={1}
            />
          </GridColumn>
        </FormGrid>
      </Form>
      <RichEditor ref={problemDetailRef} title="问题描述" />
      <RichEditor ref={answerRef} title="解决方案" style={{ marginTop: 15 }} />
    </MyModal>
  );
};
