import { observer, useForm } from '@formily/react';
import { useEffect, useRef, useState } from 'react';
// import { getWorkGroupList } from '@/common/api';
import { TreeSelect } from 'antd';
import makeRequest from '@/request';

function generateTree(
  data: any[],
  parentId: number,
  parentTitle: string = '',
): any[] {
  return data
    .filter((item) => item.parentId === parentId)
    .map((item) => {
      const showTitle = parentTitle
        ? `${parentTitle}-${item.title}`
        : item.title;
      const children = generateTree(data, item.id, showTitle);
      return children.length > 0
        ? { ...item, showTitle, children }
        : { ...item, showTitle };
    });
}

function setDisabledProperty(data: any[]) {
  return data.map((item) => {
    if (item.children) {
      item.disabled = true;
      item.children = setDisabledProperty(item.children);
    } else {
      item.disabled = false;
    }
    return item;
  });
}

const getWorkGroupList = (url: string) => {
  return makeRequest({
    url,
    method: 'GET',
  });
};

export default observer(
  ({
    pid,
    params,
    url = '/proj/group/list',
    multiple = false,
    value,
    onChange,
    ...restProps
  }: any) => {
    const [treeData, setTreeData] = useState<any[]>([]);

    const workGroupListRef = useRef<any[]>([]);

    let requestParms = { pid };
    if (params) {
      requestParms = { ...params };
    }

    const fetchData = async () => {
      const { data, err } = await getWorkGroupList(url)({
        params: requestParms,
      });
      if (err) return;
      workGroupListRef.current = data;
      const newData = data.map((item: any) => {
        return {
          value: item.id,
          title: item.name,
          id: item.id,
          parentId: item.parentId,
        };
      });
      const treeData = generateTree(newData, 0);
      setTreeData(setDisabledProperty(treeData));
    };

    useEffect(() => {
      fetchData();
    }, []);

    let newValue = value;
    if (multiple) {
      newValue =
        value && value.length > 0
          ? value.map((item: any) => {
              return item.projGroupId;
            })
          : [];
    }

    const form = useForm();

    const onSelectChange = (value: any) => {
      if (!multiple) {
        const obj = workGroupListRef.current?.find(
          (workGroup: any) => workGroup.id === value,
        );
        if (obj) {
          form.setValuesIn('parentId', obj.parentId);
        }
        onChange(value);
      } else {
        const arr: any[] = [];
        if (value && value.length === 0) {
          onChange(value);
          return;
        }
        value.forEach((item: any) => {
          const obj = workGroupListRef.current.find(
            (workGroup: any) => workGroup.id === item,
          );
          arr.push({
            projGroupId: item,
            parentId: obj.parentId,
          });
          onChange(arr);
        });
      }
    };

    return (
      <TreeSelect
        treeDefaultExpandAll
        multiple={multiple}
        treeData={treeData}
        treeNodeLabelProp="showTitle"
        {...restProps}
        value={newValue}
        onChange={onSelectChange}
      />
    );
  },
);
