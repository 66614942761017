import React, { MouseEventHandler } from 'react';

interface ColorSquareProps {
  color: string;
  size?: string;
  [key: string]: any;
}

// 定义一个颜色块组件，接受颜色、宽度和高度作为props
const ColorSquare = (props: ColorSquareProps) => {
  const { color, size = 24, ...restProps } = props;

  if (color) {
    const style = {
      width: size,
      height: size,
      backgroundColor: color,
      display: 'inline-block',
      cursor: 'pointer',
    };
    return <div style={style} {...restProps} />;
  } else {
    return <></>;
  }
};

export default ColorSquare;
