import { useModel, useAccess } from '@umijs/max';
import React from 'react';

interface IAuthBtnContainer {
  auth: string;
  children: React.ReactNode;
}

export default (props: IAuthBtnContainer) => {
  const { initialState } = useModel('@@initialState');
  const access = useAccess();

  const { currentUser } = initialState ?? {};

  if (access[props.auth] || currentUser?.admin) {
    return props.children;
  }

  return <></>;
};
