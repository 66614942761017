import makeRequest from '@/request';

export const getProblemDetail = makeRequest({
  url: '/proj/problemDetail/{id}',
  method: 'GET',
});

export const saveProblemDetail = makeRequest({
  url: '/proj/problemDetail',
  method: 'POST',
  desc: '操作',
});

export const updateProblemDetail = makeRequest({
  url: '/proj/problemDetail',
  method: 'PUT',
  desc: '操作',
});
