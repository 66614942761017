import React, { useState, useEffect, useRef } from 'react';

const AutoScrollList = ({
  items,
  height,
  renderItem,
}: {
  items: any[];
  height: number | string;
  renderItem?: (item: any, index: number) => React.ReactNode;
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollSpeed = 1; // 滚动速度，可以根据需要调整
  const ulRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const timerRef = useRef<any>(null); // 用于存储定时器的引用

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      setScrollTop((prev) => prev - scrollSpeed);
    }, 1000); // 每50毫秒滚动一次，可以根据需要调整

    return () => clearInterval(timerRef.current);
  }, []); // 只在组件挂载时启动定时器

  useEffect(() => {
    if (containerRef.current && ulRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const totalItemsHeight = ulRef.current.scrollHeight;
      if (totalItemsHeight <= containerHeight) {
        clearInterval(timerRef.current);
      } else if (Math.abs(scrollTop) === totalItemsHeight - containerHeight) {
        setScrollTop(0);
      } else {
        timerRef.current = setInterval(() => {
          setScrollTop((prev) => prev - scrollSpeed);
        }, 50);
      }
    }
    // 在组件卸载时清除定时器
    return () => clearInterval(timerRef.current);
  }, [items, scrollTop]);

  return (
    <div ref={containerRef} className="scroll-container" style={{ height }}>
      <ul ref={ulRef} className="scroll-list" style={{ top: `${scrollTop}px` }}>
        {items.map((item, index) => (
          <li key={index}>{renderItem ? renderItem(item, index) : item}</li>
        ))}
      </ul>
    </div>
  );
};

export default AutoScrollList;
