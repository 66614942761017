import { Button, Form, FormInstance, Input, Select } from 'antd';
import React from 'react';

export default (props: { form: FormInstance; doSearch: () => void }) => {
  const { form, doSearch } = props;
  return (
    <Form layout={'inline'} form={form}>
      <Form.Item name="nickName">
        <Input placeholder="按用户名搜索" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="button-color-dust"
          onClick={doSearch}
        >
          搜索
        </Button>
      </Form.Item>
    </Form>
  );
};
