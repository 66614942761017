import { UploadOutlined } from '@ant-design/icons';
import { Upload } from '@formily/antd-v5';
import { Upload as AntdUpload, message } from 'antd';
import { observer } from '@formily/react';

export default observer((props: any) => {
  const maxCount = props.maxCount || 5;
  return (
    <Upload
      beforeUpload={(file) => {
        const isImage = file.type.indexOf('image/') === 0;
        if (!isImage) {
          message.error('只能选择图片文件');
          return AntdUpload.LIST_IGNORE;
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          message.error('单个文件尺寸不能超过10M');
          return AntdUpload.LIST_IGNORE;
        }
        return false;
      }}
      {...props}
      listType="picture-card"
      accept="image/*"
    >
      {props.value?.length >= maxCount ? null : (
        <UploadOutlined style={{ fontSize: 20 }} />
      )}
    </Upload>
  );
});
