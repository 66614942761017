import { Input } from '@formily/antd-v5';
import { observer, useForm } from '@formily/react';
import React, { useState } from 'react';
import ChooseModal from './ChooseModal';

export default observer(({ value, onChange, pid }: any) => {
  const [modalVisible, setModalVisible] = useState(false);

  const form = useForm();

  const openModal = () => {
    setModalVisible(true);
  };

  const callback = (data: any[]) => {
    onChange(data.map((item) => item.nickName).join(','));
    form.setValuesIn('userList', data);
    setModalVisible(false);
  };

  return (
    <>
      <div onClick={openModal} style={{ position: 'relative' }}>
        <Input
          value={value}
          disabled={true}
          style={{ pointerEvents: 'none' }}
        />
      </div>
      {modalVisible && (
        <ChooseModal
          pid={pid}
          onCancel={() => {
            setModalVisible(false);
          }}
          callback={callback}
          defaultSelectedRows={form.getValuesIn('userList') || []}
        />
      )}
    </>
  );
});
