import { message } from 'antd';

function globalInitMap(mapOptions?: any) {
  return function (options: any) {
    let resultOptions;
    if (mapOptions) {
      if (typeof mapOptions === 'function') {
        resultOptions = mapOptions(options) || options;
      } else {
        resultOptions = mars3d.Util.merge(options, mapOptions);
      }
    }

    console.log('即将创建三维地球场景', resultOptions); // 打印测试信息

    // 创建三维地球场景
    return new mars3d.Map('mars3dContainer', resultOptions);
  };
}

export function initMap(params: {
  onMounted?: any;
  initUI?: any;
  mapOptions?: any;
}) {
  // 判断webgl支持
  if (!mars3d.Util.webglreport()) {
    mars3d.Util.webglerror();
  }
  const { onMounted, initUI, mapOptions } = params;
  // 读取 config.json 配置文件
  mars3d.Util.fetchJson({ url: '/config/config.json' })
    .then(function (json: any) {
      console.log('读取 config.json 配置文件完成', json); // 打印测试信息

      //创建三维地球场景
      const initMapFun = globalInitMap(mapOptions);

      let map = initMapFun(json.map3d);

      console.log('即将执行onMounted方法'); // 打印测试信息
      if (onMounted) {
        onMounted(map);
        console.log('已经执行onMounted方法'); // 打印测试信息
      }

      console.log('即将执行initUI方法'); // 打印测试信息
      if (initUI) {
        console.log('已经执行initUI方法'); // 打印测试信息
        initUI(map);
      }

      // if (mars3d.widget) {
      //   initWidget(map);
      // }
      // 如果模型地址内有“+”符号，可以加下面方法进行自定义处理
      Cesium.Resource.ReplaceUrl = function (url: string) {
        if (url.endsWith('.json') || url.endsWith('.b3dm')) {
          return url.replace(/\+/gm, '%2B'); // 将3dtiles中的“+”符号转义下
        } else {
          return url;
        }
      };
    })
    .catch(function (error: any) {
      console.log('加载JSON出错', error);
      message.error(error ? error.message : '加载JSON出错');
    });
}
