import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Input, Tree } from 'antd';
import { getinstitutionList, getinstitutionListByPid } from '@/common/api';
import { groupBy } from 'lodash-es';
import { DownOutlined } from '@ant-design/icons';

const { Search } = Input;

interface ISidebarProps {
  searchConditionRef: any;
  fetchUserList: () => void;
  pid?: any; // 项目id
}

export default forwardRef((props: ISidebarProps, ref) => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);

  const { searchConditionRef, fetchUserList, pid } = props;

  const fetchData = async (searchValue?: string) => {
    let data;
    if (!pid) {
      const result = await getinstitutionList({
        params: {
          searchValue,
        },
      });
      if (result.err) return;
      data = result.data;
    } else {
      const result = await getinstitutionListByPid({
        params: {
          pid,
          institutionName: searchValue,
        },
      });
      if (result.err) return;
      data = result.data;
    }
    const obj = groupBy(data, 'institutionTypeId');
    let arr = [];
    for (let key in obj) {
      arr.push({
        title: `${obj[key][0].institutionTypeName}(${obj[key].reduce(
          (total, obj) => total + obj.institutionPersonnelCount,
          0,
        )})`,
        key: key,
        isParent: true,
        children: obj[key].map((item: any) => {
          return {
            title: `${item.institutionName}(${item.institutionPersonnelCount})`,
            key: item.id,
            isParent: false,
          };
        }),
      });
    }
    setTreeData(arr);
  };

  const onSearch = (value: any) => {
    setSelectedKeys([]);
    fetchData(value);
    fetchUserList();
  };

  const onSelect = (selectedKeys: any, ev: any) => {
    const { selected, node } = ev;
    let searchCondition = {};
    if (selected) {
      let key = '';
      if (node.isParent) {
        key = 'sysInstitutionTypeId';
      } else {
        key = 'sysInstitutionId';
      }
      searchCondition = {
        [key]: node.key,
      };
      setSelectedKeys([node.key]);
    } else {
      searchCondition = {};
      setSelectedKeys([]);
    }
    searchConditionRef.current = searchCondition;
    fetchUserList();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  return (
    <div className="side-bar">
      <Search
        style={{ marginBottom: 15 }}
        placeholder="请输入机构名称"
        onSearch={onSearch}
      />
      {treeData.length ? (
        <Tree
          treeData={treeData}
          defaultExpandAll
          blockNode
          showLine
          switcherIcon={<DownOutlined />}
          selectedKeys={selectedKeys}
          onSelect={onSelect}
        />
      ) : (
        <>暂无数据</>
      )}
    </div>
  );
});
